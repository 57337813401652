<template>
  <v-dialog v-model="showDialog" :width="dialogWidth">
    <v-card>
      <v-card-text>
        <div class="text-center">
          <video loop autoplay v-if="!actionFinished && loader" :width="loader.width">
            <source :src="getLoaderPath()" type="video/webm">
          </video>
        </div>
        <div class="text-center">
          {{text}}
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'Loader',
  props:{
    dialog: {type: Boolean, default: false},
    text: {type: String, default: ''},
    actionFinished: {type: Boolean, default: false}
  },
  data(){
    return{
      showDialog: this.dialog,
      width: 800,
      loaders: [
        { path: '1.webm',
          width: '276'},
        { path: '2.webm',
          width: '700'},
        { path: '3.webm',
          width: '579'},
        { path: '4.webm',
          width: '700'},
        { path: '5.webm',
          width: '460'},
        { path: '6.webm',
          width: '640'},
        { path: '7.webm',
          width: '480'},
        { path: '8.webm',
          width: '392'},
      ],
      loader: {width: 800}
    }
  },
  watch:{
    showDialog(v){
      this.$emit('update:dialog', v)
    },
    dialog(v){
      this.showDialog = v
    }
  },
  created() {
    this.loader = this.loaders[Math.floor(Math.random()*this.loaders.length)]
    this.width = this.loader.width
  },
  computed:{
    dialogWidth(){
      const width = Number(this.loader.width) + 50
      return width.toString()
    }
  },
  methods:{
    getLoaderPath(){
      // eslint-disable-next-line import/no-dynamic-require
      return require(`@/assets/loaders/${this.loader.path}`)
    }
  }
}
</script>

<style scoped>

</style>
